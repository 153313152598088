<template lang="pug">
include ../../../configs/template
div.text-left
  div.pt-2
    +data-info('createDate','powersOfAttorney.created_at')
  div.pt-2
    +data-info('number','powersOfAttorney.number_document')
  div.pt-2
    +data-info('sailorFullName','sailorFullName')
  div.pt-2
    +data-info('manager_full_name','managerFullName')
  div.pt-2(v-if="powersOfAttorney.date_end")
    +data-info('date_end_proxy','powersOfAttorney.date_end')
  div(v-if="powersOfAttorney.comments?.length")
    h5.p-0.m-0 {{$t('comment')}}:
    div(v-for="comment in powersOfAttorney.comments" :key="comment.id").ml-4
      div(v-if="checkAccess('backOffice')").pt-2
        +data-info('createdBy', 'comment.created_by.name')
      div.pt-2
        +data-info('createDate', 'new Date(comment.created_by.date).toLocaleString()')
      div.pt-2
        +data-info('setComment', 'comment.comment')
  div.pt-2
    +data-info-status('status_document','getDirectoryObject({value: "statuses", id: powersOfAttorney.status_document})[nameLang]', 'getStatus(powersOfAttorney.status_document)')
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { getStatus } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'

export default {
  props: { powersOfAttorney: { type: Object, default: () => ({}) } },
  data () {
    return {
      checkAccess,
      getStatus
    }
  },
  computed: {
    ...mapState({
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr'
    }),
    ...mapGetters(['getDirectoryObject']),
    sailorFullName () {
      return this.powersOfAttorney.sailor[`sailor_full_${this.nameLang}`] || ''
    },
    managerFullName () {
      return this.powersOfAttorney.manager[`manager_full_${this.nameLang}`] || ''
    }
  }
}
</script>
